<template>
    <a v-if="hash" :href="`${base}/${hash}`" target="_blank" rel="noopener">{{ hash.substring(0, 8) }}</a>
    <span v-else>–</span>
</template>

<script>
export default {
    props: {
        hash: {},
    },
    data() {
        return {
            base: 'https://gitlab.com/PronounsPage/PronounsPage/-/commit',
        };
    },
};
</script>

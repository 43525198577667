<template>
    <div
        :class="['scroll-btn', 'd-print-none', config.ads && config.ads.enabled ? 'higher' : '']"
        :style="`opacity: ${shown ? 1 : 0}`"
        @click.prevent="scroll"
    >
        <SquareButton link="#" :colour="colour" :aria-label="$t('table.scrollUp')">
            <Icon v="arrow-alt-up" />
        </SquareButton>
    </div>
</template>

<script>
import useConfig from '../composables/useConfig.ts';

export default {
    props: {
        colour: { default: 'primary' },
    },
    setup() {
        return {
            config: useConfig(),
        };
    },
    data() {
        return {
            shown: false,
        };
    },
    mounted() {
        this.updateShown();
        window.addEventListener('scroll', this.updateShown);
    },
    unmounted() {
        document.removeEventListener('scroll', this.updateShown);
    },
    methods: {
        scroll() {
            document.body.scrollTop = 0;
            document.querySelector('html').scrollTop = 0;
        },
        updateShown() {
            const st = document.body.scrollTop || document.querySelector('html').scrollTop;
            this.shown = st > 300;
        },
    },
};
</script>

<style lang="scss" scoped>
    @import "assets/variables";

    .scroll-btn {
        position: fixed;
        bottom: $spacer;
        right: $spacer;
        transition: all .5s ease-in-out;
        z-index: 1030;
    }

    @include media-breakpoint-down('lg', $grid-breakpoints) {
        .higher {
            bottom: 3*$spacer;
        }
    }
    @include media-breakpoint-up('lg', $grid-breakpoints) {
        .higher {
            z-index: 100001;
            bottom: 5 * $spacer;
        }
    }
</style>

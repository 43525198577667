<script setup lang="ts">
import type { RouteLocationRaw } from 'vue-router';

const props = defineProps<{
    to: RouteLocationRaw;
}>();

const isExternal = computed((): boolean => {
    return typeof props.to === 'string' && (props.to.startsWith('https://') || props.to.startsWith('http://'));
});
</script>

<template>
    <a v-if="isExternal" :href="to as string" target="_blank" rel="noopener" :class="$attrs.class"><slot></slot></a>
    <nuxt-link v-else :to="to"><slot></slot></nuxt-link>
</template>

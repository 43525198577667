<template>
    <div class="pt-1">
        <div v-if="spellings.hasOwnProperty(config.locale)" class="btn-group m-2">
            <button
                v-for="(display, code) in spellings[config.locale]"
                :class="['btn btn-sm', getSpelling() === code ? 'btn-secondary disabled' : 'btn-outline-secondary', code === 'sitelen' ? 'sitelen' : '']"
                :disabled="getSpelling() === code"
                @click="setSpelling(code)"
            >
                {{ display }}
            </button>
        </div>
        <Dropdown
            v-if="Object.keys($locales).length > 1"
            btn-class="btn-outline-secondary btn-sm"
            class="d-inline-block"
            :end="end"
            menu-class="locale-dropdown shadow"
        >
            <template #toggle>
                <Icon v="language" />
                {{ $locales[config.locale].name }}
                <small v-if="$locales[config.locale].extra" class="text-muted">
                    ({{ $locales[config.locale].extra }})
                </small>
            </template>

            <template #menu>
                <template v-for="(options, locale) in $locales">
                    <li v-if="locale !== config.locale" :key="locale" class="w-50">
                        <a :href="options.url" class="dropdown-item">
                            <LocaleIcon :locale="options" />
                            {{ options.name }}
                            <small v-if="options.extra" class="text-muted">({{ options.extra }})</small>
                        </a>
                    </li>
                </template>
                <li class="dropdown-divider"></li>
                <li class="w-50">
                    <a href="https://pronouns.page" class="dropdown-item small">
                        <Logo class="mb-1" />
                        pronouns.page
                    </a>
                </li>
                <li class="w-50">
                    <LocaleLink locale="en" link="/new-version" class="dropdown-item small">
                        <Icon v="plus" />
                        <T>localise.shorter</T>
                    </LocaleLink>
                </li>
            </template>
        </Dropdown>
    </div>
</template>

<script>
import { mapState } from 'pinia';

import useConfig from '../composables/useConfig.ts';
import { longtimeCookieSetting } from '../src/cookieSettings.ts';
import { useMainStore } from '../store/index.ts';

export default {
    props: {
        end: { type: Boolean },
    },
    setup() {
        const spellingCookie = useCookie('spelling', longtimeCookieSetting);
        return {
            config: useConfig(),
            store: useMainStore(),
            spellingCookie,
        };
    },
    data() {
        return {
            spellings: {
                zh: { traditional: '繁體', simplified: '简体' },
                tok: { latin: 'sitelen Lasina', sitelen: 'sitelen\xa0pona' },
            },
        };
    },
    methods: {
        getSpelling() {
            return this.spelling || (
                this.spellings.hasOwnProperty(this.config.locale)
                    ? Object.keys(this.spellings[this.config.locale])[0]
                    : null
            );
        },
        setSpelling(spelling) {
            this.store.setSpelling(spelling);
            this.spellingCookie = this.store.spelling;
        },
    },
    computed: {
        ...mapState(useMainStore, [
            'spelling',
        ]),
    },
};
</script>

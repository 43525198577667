<template>
    <div v-if="requiresLogin && !testerPasswordValid" class="body">
        <div class="container">
            <div class="alert alert-warning m-3 text-center">
                <Icon v="exclamation-triangle" />
                This is a test server
            </div>
            <div class="m-3">
                <div class="input-group py-1">
                    <input v-model="testerPassword" class="form-control" type="password" placeholder="Password" @keydown.enter.prevent="checkTesterPassword">
                    <button type="button" class="btn btn-primary btn-sm border" @click.prevent="checkTesterPassword">
                        <Icon v="sign-in" />
                        Sign in
                    </button>
                </div>
                <p v-if="testerPasswordCookie && !testerPasswordValid" class="small text-danger">
                    <Icon v="exclamation-triangle" />
                    Password invalid
                </p>
            </div>
        </div>
    </div>
    <div v-else class="body">
        <div class="flex-grow-1 vh">
            <Header />
            <slot></slot>
            <TranslationMode />
            <ScrollButton />
        </div>
        <Footer />
        <Lightbox />
    </div>
</template>

<script lang="ts">
import sorter from 'avris-sorter';
import md5 from 'js-md5';
import { useCookie } from 'nuxt/app';
import { defineComponent } from 'vue';

import useConfig from '../composables/useConfig.ts';
import useDark from '../composables/useDark.ts';
import useDialogue from '../composables/useDialogue.ts';
import { longtimeCookieSetting } from '../src/cookieSettings.ts';
import { LoadScriptError } from '../src/errors.ts';

// no need to be super secure, just a sign that the page is not public
const TESTER_PASSWORD_HASH = '82feeb96d60170e714df8fb062301e90';

declare global {
    interface Window {
        dataLayer: unknown[];
    }
}

export default defineComponent({
    setup() {
        useDark();
        const testerPasswordCookie = useCookie('tester-password', longtimeCookieSetting);
        return {
            config: useConfig(),
            dialogue: useDialogue(),
            testerPasswordCookie,
        };
    },
    data(): { testerPassword: string } {
        return {
            testerPassword: '',
        };
    },
    computed: {
        adsEnabled(): boolean {
            if (this.$isGranted()) {
                const adsVisible = parseInt(localStorage.getItem('adsVisible') || '0') === 1;
                if (!adsVisible) {
                    return false;
                }
            }

            return !!this.config.ads?.enabled && process.env.NODE_ENV !== 'development';
        },
        requiresLogin(): boolean {
            return this.$config.public.env === 'test' ||
                this.config.locale !== '_' && !this.$locales[this.config.locale]?.published;
        },
        testerPasswordValid(): boolean {
            return !!this.testerPasswordCookie && md5(this.testerPasswordCookie) === TESTER_PASSWORD_HASH;
        },
    },
    mounted() {
        sorter();

        this.confirmAge();

        this.loadAds();
        this.loadGTM();

        let needsRefresh = false;
        const bc = new BroadcastChannel('account_switch');
        bc.onmessage = (ev): void => {
            if (ev.data !== this.$user()?.username) {
                needsRefresh = true;
                if (document.hasFocus()) {
                    needsRefresh = false;
                    window.location.reload();
                }
            }
        };
        window.onfocus = (): void => {
            if (needsRefresh) {
                needsRefresh = false;
                window.location.reload();
            }
        };
    },
    methods: {
        async confirmAge(): Promise<void> {
            if (!this.$te('footer.ageLimit') || localStorage.getItem('ageConfirmed')) {
                return;
            }

            await this.dialogue.alert(this.$t('footer.ageLimit'));

            localStorage.setItem('ageConfirmed', '1');
        },
        async loadAds(): Promise<void> {
            if (!this.adsEnabled) {
                return;
            }

            try {
                await this.$loadScript('publift', 'https://cdn.fuseplatform.net/publift/tags/2/3329/fuse.js');
                await this.$loadScript('publift-video', 'https://live.primis.tech/live/liveView.php?s=118558&schain=1.0,1!publift.com,[01H9H7XDCTSKKX1ECPR1VWQXQ9],1');
            } catch (error) {
                if (error instanceof LoadScriptError) {
                    return;
                }
                throw error;
            }
        },
        async loadGTM(): Promise<void> {
            if (!this.adsEnabled) {
                return;
            }

            try {
                await this.$loadScript('gtm', 'https://www.googletagmanager.com/gtag/js?id=G-TDJEP12Q3M');
            } catch (error) {
                if (error instanceof LoadScriptError) {
                    return;
                }
                throw error;
            }

            window.dataLayer = window.dataLayer || [];
            function gtag(...args: unknown[]): void {
                window.dataLayer.push(args);
            }
            gtag('js', new Date());
            gtag('config', 'G-TDJEP12Q3M');
        },
        checkTesterPassword(): void {
            this.testerPasswordCookie = this.testerPassword;
        },
    },
});
</script>

<style lang="scss">
    @import "assets/variables";
    @import "~avris-sorter/dist/Sorter.min.css";

    .vh {
        min-height: calc(100vh - #{$header-height});
    }
</style>

<template>
    <div v-if="egg && spelling === egg.spelling" class="my-5">
        <Separator icon="smile-wink" />
        <div class="row my-5">
            <div class="col-12 col-md-6 offset-md-3">
                <div v-if="config.locale === 'pl'" class="alert alert-warning">
                    <p>
                        <Spelling text="Gratulacje! Udało Ci się odblokować" /> <a href="https://www.webopedia.com/definitions/easter-egg/" target="_blank" rel="noopener">easter egga</a>!
                    </p>
                    <p>
                        <Spelling text="Tak wyglądałaby nasza strona, jeśli trzymałobyśmy się postulatów" />
                        <a href="https://futurysci.avris.it" target="_blank" rel="noopener">Manifestu Brunona Jaśeńskiego w sprawie ortografji fonetycznej.</a>
                    </p>
                    <p>
                        <a href="https://futurysci.avris.it" target="_blank" rel="noopener" class="btn btn-primary btn-sm"><Spelling text="Więcej informacji" /></a>
                        <button class="btn btn-outline-danger btn-sm" @click="setSpelling('')">
                            <Spelling text="Wyłącz" />
                        </button>
                    </p>
                </div>
                <div v-else-if="config.locale === 'en'" class="alert alert-warning">
                    <p>
                        <Spelling text="Congrats! You've discovered" /> <a href="https://www.webopedia.com/definitions/easter-egg/" target="_blank" rel="noopener">an easter egg</a>!
                    </p>
                    <p>
                        <Spelling text="This is what our page would look like, if we adapted" />
                        <a href="https://www.shavian.info/" target="_blank" rel="noopener">Shavian – an alternative alphabet for English</a>.
                    </p>
                    <p>
                        <a href="https://www.shavian.info/" target="_blank" rel="noopener" class="btn btn-primary btn-sm"><Spelling text="More info" /></a>
                        <button class="btn btn-outline-danger btn-sm" @click="setSpelling('')">
                            <Spelling text="Disable" />
                        </button>
                    </p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapState } from 'pinia';

import useConfig from '../composables/useConfig.ts';
import useDialogue from '../composables/useDialogue.ts';
import { longtimeCookieSetting } from '../src/cookieSettings.ts';
import { useMainStore } from '../store/index.ts';

const konami = ['ArrowUp', 'ArrowUp', 'ArrowDown', 'ArrowDown', 'ArrowLeft', 'ArrowRight', 'ArrowLeft', 'ArrowRight', 'b', 'a'];

const EGGS_BY_LOCALE = {
    pl: {
        spelling: 'futurysci',
        message: 'Gratulacje! Udało Ći śę odblokować easter egga!<br/>Szczeguły w stopce strony, u samego dołu!',
    },
    en: {
        spelling: 'shavian',
        message: 'Congrats! You\'ve discovered an easter egg!<br/>Check out details in the footer, all the way down!',
        init() {
            return new Promise((resolve, reject) => {
                const font = document.createElement('link');
                font.href = 'https://fonts.googleapis.com/css2?family=Noto+Sans+Shavian&display=swap';
                font.rel = 'stylesheet';
                document.head.appendChild(font);

                const script = document.createElement('script');
                script.src = 'https://unpkg.com/to-shavian@0.3.1/dist/index.js';
                script.onload = () => resolve();
                script.onerror = () => reject(new Error(`Script load error for ${script.src}`));
                document.head.appendChild(script);
            });
        },
    },
};

export default {
    setup() {
        const spellingCookie = useCookie('spelling', longtimeCookieSetting);
        return {
            config: useConfig(),
            store: useMainStore(),
            dialogue: useDialogue(),
            spellingCookie,
        };
    },
    data() {
        return {
            lastCharacters: [],
            egg: EGGS_BY_LOCALE[this.config.locale],
        };
    },
    mounted() {
        if (this.egg === undefined) {
            return;
        }

        window.addEventListener('keydown', this.keypressed);
    },
    beforeUnmount() {
        window.removeEventListener('keydown', this.keypressed);
    },
    methods: {
        async keypressed(e) {
            this.lastCharacters = [...this.lastCharacters.slice(-9), e.key];
            if (this.lastCharacters.join(',') === konami.join(',')) {
                await (this.egg.init || async function () {})();
                await this.dialogue.alert(this.egg.message);
                this.setSpelling(this.egg.spelling);
            }
        },
        setSpelling(spelling) {
            this.store.setSpelling(spelling);
            this.spellingCookie = this.store.spelling;
        },
    },
    computed: {
        ...mapState(useMainStore, [
            'spelling',
        ]),
    },
};
</script>
